<template>
      <div id="app">
            <div data-app="true" class="application theme--light" id="app" style="font-family: Futura;">
                  <div class="application--wrap">
                        <main class="v-content" data-booted="true" style="padding: 0px;">
                              <div class="v-content__wrap">
                                    <div>
                                          <div class="layout row" style="padding-bottom: 64px;">
                                                <div class="flex xs12 sm6 offset-sm3">
                                                      <div class="v-card v-sheet theme--light">
                                                            <nav class="v-toolbar theme--dark primary" data-booted="true"
                                                                  style="margin-top: 0px; padding-right: 0px; padding-left: 0px; transform: translateY(0px);">
                                                                  <div class="v-toolbar__content" style="height: 56px;">
                                                                        <div class="v-toolbar__title">Amusebox</div>
                                                                        <div class="spacer"></div>
                                                                  </div>
                                                            </nav>
                                                            <div class="v-card v-sheet theme--light">
                                                                  <div class="container fluid grid-list-lg">
                                                                        <div class="layout row wrap">
                                                                              <div class="flex xs12">
                                                                                    <div
                                                                                          class="white--text v-card v-sheet theme--light cyan darken-2">
                                                                                          <div class="layout">
                                                                                                <div class="flex xs5">
                                                                                                      <div class="v-responsive v-image"
                                                                                                            style="height: 125px;">
                                                                                                            <div class="v-responsive__sizer"
                                                                                                                  style="padding-bottom: 100%;">
                                                                                                            </div>
                                                                                                            <div class="v-image__image v-image__image--contain"
                                                                                                                  style="background-image: url(&quot;https://img.cooe.in/media/unnamed.webp&quot;); background-position: center center;">
                                                                                                            </div>
                                                                                                            <div
                                                                                                                  class="v-responsive__content">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                                <div class="flex xs7">
                                                                                                      <div class="v-card__title v-card__title--primary"
                                                                                                            style="padding: 0px 16px;">
                                                                                                            <div>
                                                                                                                  <div
                                                                                                                        class="headline">
                                                                                                                        Mario
                                                                                                                        Kart
                                                                                                                        Tour
                                                                                                                  </div>
                                                                                                                  <div
                                                                                                                        style="width: 100%; word-break: break-all; overflow-wrap: break-word;">
                                                                                                                        ■Challenge
                                                                                                                        players
                                                                                                                        worldwide
                                                                                                                        in
                                                                                                                        multiplayer!
                                                                                                                        You
                                                                                                                        can
                                                                                                                        race
                                                                                                                        against
                                                                                                                        up
                                                                                                                        to
                                                                                                                        seven
                                                                                                                        other
                                                                                                                        players,
                                                                                                                        whether
                                                                                                                        they're
                                                                                                                        registered
                                                                                                                        as
                                                                                                                        in-game
                                                                                                                        friends
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <hr class="v-divider theme--light">
                                                                                          <div class="v-card__actions pa-3">
                                                                                                View this game
                                                                                                <div class="spacer">
                                                                                                </div>
                                                                                                <div
                                                                                                      class="v-list__tile__action">
                                                                                                      <button type="button"
                                                                                                            class="v-btn v-btn--small theme--dark cyan darken-2">
                                                                                                            <div
                                                                                                                  class="v-btn__content">
                                                                                                                  Start
                                                                                                            </div>
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              <div class="flex xs12">
                                                                                    <div
                                                                                          class="white--text v-card v-sheet theme--light purple">
                                                                                          <div class="layout">
                                                                                                <div class="flex xs5">
                                                                                                      <div class="v-responsive v-image"
                                                                                                            style="height: 125px;">
                                                                                                            <div class="v-responsive__sizer"
                                                                                                                  style="padding-bottom: 100%;">
                                                                                                            </div>
                                                                                                            <div class="v-image__image v-image__image--contain"
                                                                                                                  style="background-image: url(&quot;https://img.cooe.in/media/bts.webp&quot;); background-position: center center;">
                                                                                                            </div>
                                                                                                            <div
                                                                                                                  class="v-responsive__content">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                                <div class="flex xs7">
                                                                                                      <div class="v-card__title v-card__title--primary"
                                                                                                            style="padding: 0px 16px;">
                                                                                                            <div>
                                                                                                                  <div
                                                                                                                        class="headline">
                                                                                                                        BTS
                                                                                                                        WORLD
                                                                                                                  </div>
                                                                                                                  <div
                                                                                                                        style="width: 100%; word-break: break-all; overflow-wrap: break-word;">
                                                                                                                        It's
                                                                                                                        2012－and
                                                                                                                        you
                                                                                                                        work
                                                                                                                        at
                                                                                                                        Big
                                                                                                                        Hit.
                                                                                                                        As
                                                                                                                        BTS's
                                                                                                                        manager,
                                                                                                                        their
                                                                                                                        debut
                                                                                                                        is
                                                                                                                        up
                                                                                                                        to
                                                                                                                        you!

                                                                                                                        Is
                                                                                                                        this
                                                                                                                        the
                                                                                                                        beginning
                                                                                                                        of
                                                                                                                        your
                                                                                                                        success
                                                                                                                        story?
                                                                                                                        Will
                                                                                                                        you
                                                                                                                        be
                                                                                                                        able
                                                                                                                        to
                                                                                                                        go
                                                                                                                        back
                                                                                                                        to
                                                                                                                        your
                                                                                                                        own
                                                                                                                        world?
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <hr class="v-divider theme--light">
                                                                                          <div class="v-card__actions pa-3">
                                                                                                View this game
                                                                                                <div class="spacer">
                                                                                                </div>
                                                                                                <div
                                                                                                      class="v-list__tile__action">
                                                                                                      <button type="button"
                                                                                                            class="v-btn v-btn--small theme--dark purple">
                                                                                                            <div
                                                                                                                  class="v-btn__content">
                                                                                                                  Start
                                                                                                            </div>
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              <div class="flex xs12">
                                                                                    <div
                                                                                          class="white--text v-card v-sheet theme--light light-blue darken-1">
                                                                                          <div class="layout">
                                                                                                <div class="flex xs5">
                                                                                                      <div class="v-responsive v-image"
                                                                                                            style="height: 125px;">
                                                                                                            <div class="v-responsive__sizer"
                                                                                                                  style="padding-bottom: 100%;">
                                                                                                            </div>
                                                                                                            <div class="v-image__image v-image__image--contain"
                                                                                                                  style="background-image: url(&quot;https://img.cooe.in/media/unnamed_gck4Dnp.webp&quot;); background-position: center center;">
                                                                                                            </div>
                                                                                                            <div
                                                                                                                  class="v-responsive__content">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                                <div class="flex xs7">
                                                                                                      <div class="v-card__title v-card__title--primary"
                                                                                                            style="padding: 0px 16px;">
                                                                                                            <div>
                                                                                                                  <div
                                                                                                                        class="headline">
                                                                                                                        Harry
                                                                                                                        Potter:
                                                                                                                        Wizards
                                                                                                                        Unite
                                                                                                                  </div>
                                                                                                                  <div
                                                                                                                        style="width: 100%; word-break: break-all; overflow-wrap: break-word;">
                                                                                                                        A
                                                                                                                        magical
                                                                                                                        calamity
                                                                                                                        has
                                                                                                                        befallen
                                                                                                                        the
                                                                                                                        wizarding
                                                                                                                        world
                                                                                                                        and
                                                                                                                        it’s
                                                                                                                        up
                                                                                                                        to
                                                                                                                        you
                                                                                                                        to
                                                                                                                        answer
                                                                                                                        the
                                                                                                                        call
                                                                                                                        to
                                                                                                                        help
                                                                                                                        the
                                                                                                                        Ministry
                                                                                                                        of
                                                                                                                        Magic
                                                                                                                        restore
                                                                                                                        balance!
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <hr class="v-divider theme--light">
                                                                                          <div class="v-card__actions pa-3">
                                                                                                View this game
                                                                                                <div class="spacer">
                                                                                                </div>
                                                                                                <div
                                                                                                      class="v-list__tile__action">
                                                                                                      <button type="button"
                                                                                                            class="v-btn v-btn--small theme--dark light-blue darken-1">
                                                                                                            <div
                                                                                                                  class="v-btn__content">
                                                                                                                  Start
                                                                                                            </div>
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              <div class="flex xs12">
                                                                                    <div
                                                                                          class="white--text v-card v-sheet theme--light green darken-3">
                                                                                          <div class="layout">
                                                                                                <div class="flex xs5">
                                                                                                      <div class="v-responsive v-image"
                                                                                                            style="height: 125px;">
                                                                                                            <div class="v-responsive__sizer"
                                                                                                                  style="padding-bottom: 100%;">
                                                                                                            </div>
                                                                                                            <div class="v-image__image v-image__image--contain"
                                                                                                                  style="background-image: url(&quot;https://img.cooe.in/media/unnamed_nx8P0yl.webp&quot;); background-position: center center;">
                                                                                                            </div>
                                                                                                            <div
                                                                                                                  class="v-responsive__content">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                                <div class="flex xs7">
                                                                                                      <div class="v-card__title v-card__title--primary"
                                                                                                            style="padding: 0px 16px;">
                                                                                                            <div>
                                                                                                                  <div
                                                                                                                        class="headline">
                                                                                                                        Call
                                                                                                                        of
                                                                                                                        Duty®:
                                                                                                                        Mobile
                                                                                                                        -
                                                                                                                        Garena
                                                                                                                  </div>
                                                                                                                  <div
                                                                                                                        style="width: 100%; word-break: break-all; overflow-wrap: break-word;">
                                                                                                                        Call
                                                                                                                        of
                                                                                                                        Duty®:
                                                                                                                        Mobile
                                                                                                                        -
                                                                                                                        Garena's
                                                                                                                        latest
                                                                                                                        update
                                                                                                                        brings
                                                                                                                        you
                                                                                                                        the
                                                                                                                        new
                                                                                                                        revolutionary
                                                                                                                        customization
                                                                                                                        feature
                                                                                                                        and
                                                                                                                        many
                                                                                                                        more
                                                                                                                        cool
                                                                                                                        additions!
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <hr class="v-divider theme--light">
                                                                                          <div class="v-card__actions pa-3">
                                                                                                View this game
                                                                                                <div class="spacer">
                                                                                                </div>
                                                                                                <div
                                                                                                      class="v-list__tile__action">
                                                                                                      <button type="button"
                                                                                                            class="v-btn v-btn--small theme--dark green darken-3">
                                                                                                            <div
                                                                                                                  class="v-btn__content">
                                                                                                                  Start
                                                                                                            </div>
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              <div class="flex xs12">
                                                                                    <div
                                                                                          class="white--text v-card v-sheet theme--light amber accent-3">
                                                                                          <div class="layout">
                                                                                                <div class="flex xs5">
                                                                                                      <div class="v-responsive v-image"
                                                                                                            style="height: 125px;">
                                                                                                            <div class="v-responsive__sizer"
                                                                                                                  style="padding-bottom: 100%;">
                                                                                                            </div>
                                                                                                            <div class="v-image__image v-image__image--contain"
                                                                                                                  style="background-image: url(&quot;https://img.cooe.in/media/unnamed_d8AyUvg.webp&quot;); background-position: center center;">
                                                                                                            </div>
                                                                                                            <div
                                                                                                                  class="v-responsive__content">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                                <div class="flex xs7">
                                                                                                      <div class="v-card__title v-card__title--primary"
                                                                                                            style="padding: 0px 16px;">
                                                                                                            <div>
                                                                                                                  <div
                                                                                                                        class="headline">
                                                                                                                        State
                                                                                                                        of
                                                                                                                        Survival:
                                                                                                                        Survive
                                                                                                                        the
                                                                                                                        Zombie
                                                                                                                        Apocalypse
                                                                                                                  </div>
                                                                                                                  <div
                                                                                                                        style="width: 100%; word-break: break-all; overflow-wrap: break-word;">
                                                                                                                        "It's
                                                                                                                        been
                                                                                                                        six
                                                                                                                        months
                                                                                                                        since
                                                                                                                        the
                                                                                                                        zombie
                                                                                                                        apocalypse
                                                                                                                        began.
                                                                                                                        The
                                                                                                                        virus
                                                                                                                        has
                                                                                                                        infected
                                                                                                                        the
                                                                                                                        city.
                                                                                                                        Six
                                                                                                                        months
                                                                                                                        of
                                                                                                                        terror,
                                                                                                                        horror,
                                                                                                                        survival,
                                                                                                                        and
                                                                                                                        fighting
                                                                                                                        against
                                                                                                                        zombies.
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <hr class="v-divider theme--light">
                                                                                          <div class="v-card__actions pa-3">
                                                                                                View this game
                                                                                                <div class="spacer">
                                                                                                </div>
                                                                                                <div
                                                                                                      class="v-list__tile__action">
                                                                                                      <button type="button"
                                                                                                            class="v-btn v-btn--small theme--dark amber accent-3">
                                                                                                            <div
                                                                                                                  class="v-btn__content">
                                                                                                                  Start
                                                                                                            </div>
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              <div class="flex xs12">
                                                                                    <div
                                                                                          class="white--text v-card v-sheet theme--light blue-grey darken-1">
                                                                                          <div class="layout">
                                                                                                <div class="flex xs5">
                                                                                                      <div class="v-responsive v-image"
                                                                                                            style="height: 125px;">
                                                                                                            <div class="v-responsive__sizer"
                                                                                                                  style="padding-bottom: 100%;">
                                                                                                            </div>
                                                                                                            <div class="v-image__image v-image__image--contain"
                                                                                                                  style="background-image: url(&quot;https://img.cooe.in/media/unnamed_dd2Rtdr.webp&quot;); background-position: center center;">
                                                                                                            </div>
                                                                                                            <div
                                                                                                                  class="v-responsive__content">
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                                <div class="flex xs7">
                                                                                                      <div class="v-card__title v-card__title--primary"
                                                                                                            style="padding: 0px 16px;">
                                                                                                            <div>
                                                                                                                  <div
                                                                                                                        class="headline">
                                                                                                                        Rise
                                                                                                                        of
                                                                                                                        Empires:
                                                                                                                        Ice
                                                                                                                        and
                                                                                                                        Fire
                                                                                                                  </div>
                                                                                                                  <div
                                                                                                                        style="width: 100%; word-break: break-all; overflow-wrap: break-word;">
                                                                                                                        Real
                                                                                                                        Time
                                                                                                                        Nation
                                                                                                                        vs.
                                                                                                                        Nation
                                                                                                                        medieval
                                                                                                                        strategy
                                                                                                                        war
                                                                                                                        game.
                                                                                                                        Join
                                                                                                                        now!
                                                                                                                        Train
                                                                                                                        your
                                                                                                                        troops
                                                                                                                        and
                                                                                                                        go
                                                                                                                        to
                                                                                                                        war!
                                                                                                                        Rise
                                                                                                                        of
                                                                                                                        Empires
                                                                                                                        is
                                                                                                                        a
                                                                                                                        Massive
                                                                                                                        Multi-Player,
                                                                                                                        Real-Time
                                                                                                                        strategy
                                                                                                                        war
                                                                                                                        game.
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                </div>
                                                                                          </div>
                                                                                          <hr class="v-divider theme--light">
                                                                                          <div class="v-card__actions pa-3">
                                                                                                View this game
                                                                                                <div class="spacer">
                                                                                                </div>
                                                                                                <div
                                                                                                      class="v-list__tile__action">
                                                                                                      <button type="button"
                                                                                                            class="v-btn v-btn--small theme--dark blue-grey darken-1">
                                                                                                            <div
                                                                                                                  class="v-btn__content">
                                                                                                                  Start
                                                                                                            </div>
                                                                                                      </button>
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </main>
                        <div class="v-card v-card--flat v-sheet theme--light">
                              <div class="v-item-group v-bottom-nav theme--light v-bottom-nav--fixed v-bottom-nav--active"
                                    style="height: 56px;">
                                    <div class="layout justify-space-between"><a href="/#/"
                                                class="v-btn--active v-btn v-btn--flat v-btn--router theme--light primary--text"
                                                value="home" aria-current="page">
                                                <div class="v-btn__content"><span>Amuse</span><i aria-hidden="true"
                                                            class="v-icon material-icons theme--light">sports_esports</i>
                                                </div>
                                          </a><a @click="check('win')"
                                                class="v-btn v-btn--flat v-btn--router theme--light primary--text"
                                                value="win">
                                                <div class="v-btn__content"><span>Win</span><i aria-hidden="true"
                                                            class="v-icon material-icons theme--light">wine_bar</i>
                                                </div>
                                          </a><a href="/#/mine"
                                                class="v-btn v-btn--flat v-btn--router theme--light primary--text"
                                                value="mine">
                                                <div class="v-btn__content"><span>Mine</span><i aria-hidden="true"
                                                            class="v-icon material-icons theme--light">face</i></div>
                                          </a></div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</template>
<script>
export default {
      name: "HomeView",
      data() {
            return {
                  count: 1
            }

      },
      beforeUnmount: function () {
            console.log("unmount");
            clearInterval(this.repeat);

      },
      mounted: function () {

      },
      methods: {
            check(path) {
                  if (localStorage.getItem('username') == null) {
                        window.location.href = "/#/login";
                  } else {
                        if (path == 'win') {
                              window.location.href = "/#/win";
                        } else if (path == 'aviator') {
                              window.location.href = "/#/aviator";
                        }

                  }
            },
            slide() {
                  document.querySelectorAll(".slides img").forEach(function (photo) {
                        photo.classList.remove("outleft");
                        photo.classList.remove("outright");
                  });
                  document.querySelectorAll(".slides img")[this.count - 1].classList.remove("inleft");
                  document.querySelectorAll(".slides img")[this.count - 1].classList.remove("inright");
                  document.querySelectorAll(".slides img")[this.count - 1].classList.add("outleft");
                  this.count = this.count + 1;
                  // *** Looping document.querySelectorAll(".slides img") *** //
                  if (this.count > document.querySelectorAll(".slides img").length) { this.count = 1; } // *** if reached end go to first slide *** //
                  if (this.count < 1) { this.count = 4; } // *** if reached first go to last one *** //


                  document.querySelectorAll(".slides img")[this.count - 1].classList.add("inleft");
                  console.log(this.count);
            }
      }
}
</script>

<style>
.button,
.checkmark {
      background-color: #dedede;
      border: 0;
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(190, 190, 220, 0.2);
      color: white;
      height: 30px;
      margin: 0 0.5rem 0 0;
      cursor: pointer;
      position: relative;
      transition: box-shadow 0.2s;
      width: 30px;
}

.button:hover,
.checkmark:hover {
      background-color: #ccc;
}

.button:active,
.checkmark:active {
      background: #2196F3;
      box-shadow: 0 0 15px 40px rgba(220, 220, 250, 0);
}

.autoplay-controls {
      cursor: pointer;
}

.autoplay-controls:active>.checkmark {
      background: #2196F3;
      box-shadow: 0 0 15px 40px rgba(220, 220, 250, 0);
}

.slider_container {
      align-items: center;
      display: flex;
      flex-direction: column;
      min-height: 400px;
      width: 900px;
}

.slide_this.counter {
      float: right;
      margin: 0 1rem;
}

.slides {
      height: 500px;
      overflow: hidden;
      position: relative;
      width: 900px;
}

.inright {
      -webkit-animation: goInL 3s cubic-bezier(1, 1.59, 0.61, 0.74);
      animation: goInL 3s cubic-bezier(1, 1.59, 0.61, 0.74);
      /* ... */
}

.outright {
      -webkit-animation: goOutL 3s cubic-bezier(1, 1.59, 0.61, 0.74);
      animation: goOutL 3s cubic-bezier(1, 1.59, 0.61, 0.74);
      /* ... */
}

.inleft {
      -webkit-animation: goInR 3s cubic-bezier(1, 1.4, 0.61, 0.74);
      animation: goInR 3s cubic-bezier(1, 1.4, 0.61, 0.74);
      /* ... */
}

.outleft {
      -webkit-animation: goOutR 1.8s cubic-bezier(1, 1.59, 0.61, 0.74);
      animation: goOutR 1.8s cubic-bezier(1, 1.59, 0.61, 0.74);
      /* ... */
}

.navigation-buttons {
      display: inherit;
      align-items: center;
      flex-direction: row;
      justify-content: center;
}

.slider_navigation {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      height: 20px;
      justify-content: space-between;
      position: relative;
      width: 100%;
}



.autoplay-sub {
      float: left;
      line-height: 30px;
      font-size: 0.7rem;
}

.checkmark {
      float: left;
}

.checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 11px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
}

.button-left:after,
.button-right:after {
      border: solid white;
      border-width: 0 3px 3px 0;
      content: "";
      display: block;
      height: 8px;
      position: absolute;
      width: 8px;
}

.button-left:after {
      left: 11px;
      top: 10px;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
}

.button-right:after {
      left: 7px;
      top: 10px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
}



@-webkit-keyframes goInL {
      0% {
            left: -900px;
      }

      100% {
            left: 0;
      }
}

@keyframes goInL {
      0% {
            left: -900px;
      }

      100% {
            left: 0;
      }
}



@-webkit-keyframes goOutL {
      0% {
            left: 0;
      }

      100% {
            left: 900px;
      }
}

@keyframes goOutL {
      0% {
            left: 0;
      }

      100% {
            left: 900px;
      }
}


@-webkit-keyframes goInR {
      0% {
            left: 900px;
      }

      50% {
            left: 400px;
      }

      100% {
            left: 0;
      }
}

@keyframes goInR {
      0% {
            left: 900px;
      }

      50% {
            left: 400px;
      }

      100% {
            left: 0;
      }
}



@-webkit-keyframes goOutR {
      0% {
            left: 0;
      }

      50% {
            left: -450px;
      }

      100% {
            left: -900px;
      }
}

@keyframes goOutR {
      0% {
            left: 0;
      }

      50% {
            left: -450px;
      }

      100% {
            left: -900px;
      }
}

.pic {
      left: 0;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 45%;
}

.pic:first-child {
      z-index: 100;
}
</style>
<style scoped>
@import '@/assets/index.css';
</style>


